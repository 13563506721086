/* .ant-layout-header .ant-menu {
    background-color: #333;
  }
  
  .ant-layout-header .ant-menu-item {
    color: #ddd;
  }
  
  .ant-layout-header .ant-menu-item:hover {
    background-color: #555;
  } */



  .ant-menu {
    /* Remove fixed width if set */
    flex: 1; /* Expand to fill available space */
  }

